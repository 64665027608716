import React, { useEffect } from 'react';
import BackgroundImg from '../img/jury-bg.jpg';

const Jury = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <div>
    <div className="main-content" style={{
  backgroundImage: `linear-gradient(rgba(24, 24, 24, 0.5), rgba(24, 24, 24, 0.5)), url(${BackgroundImg})`
}}>
    <div className="content-overlay">
      <div className="main-sub-title">Жюри</div>
      <div className="main-title">Global Beauty Leader</div>
    </div>
  </div>
  <div className="about-content">
  <div className='about-title'>Жюри Международной Премии</div>
    <h1 className="secondary-title">Global Beauty Leader</h1>
    <p className='about-text'>В жюри Международной Премии Global Beauty Leader входят только самые выдающиеся мастера в сфере красоты, каждый из которых обладает международным признанием и значительным вкладом в индустрию. Стать членом жюри нашей Международной Премии — значит подтвердить свой высокий профессиональный уровень и безусловное лидерство.</p>
    <p className='about-text'>Жюри премии состоит из исключительных личностей мирового уровня в индустрии красоты, каждый из которых приносит свой уникальный опыт и перспективу в процесс оценки. Это не просто эксперты; это инноваторы, лидеры мнений и практиков, которые формируют будущее индустрии.</p>

        <h2 className="secondary-sub-title mt-50">Критерии отбора членов жюри</h2>
        <h1 className="secondary-title">Expertise, Innovation, Recognition</h1>
        <div class="third-container">
        <div class="third-section">
          <h2>Международное признание</h2>
          <p>Члены жюри должны быть признанными экспертами в своих областях, с узнаваемым вкладом на международной арене.</p>
        </div>
        <div class="third-section">
          <h2>Опыт работы</h2>
          <p>Не менее трех лет активной профессиональной деятельности в индустрии красоты.</p>
        </div>
        <div class="third-section">
          <h2>Вклад в индустрию</h2>
          <p>Наличие значимых достижений, таких как разработка новаторских методов, участие в крупных международных проектах, или ведение образовательной деятельности в области красоты.</p>
        </div>
          </div>


        <h2 className="secondary-sub-title mt-50">Состав жюри</h2>
        <h1 className="secondary-title">Expertise, Diversity, Judgment</h1>
        <p className='about-text'>Члены жюри отбираются на основании их профессиональных достижений, вклада в индустрию красоты и способности критически оценивать инновации и мастерство.</p>
        <p className='about-text'>Жюри включает в себя известных косметологов, мастеров маникюра и педикюра, стилистов, мастеров эстетической медицины, парикмахеров, мастеров макияжа и других специалистов с международным признанием.</p>
        <p className='about-text'>Каждый член жюри имеет не менее трех лет профессионального опыта в своей сфере и обладает знаниями, которые позволяют глубоко анализировать представленные работы на предмет их технической сложности, инновационности и креативности.</p>

        <h2 className="secondary-sub-title mt-50">Роль жюри</h2>
        <h1 className="secondary-title">Assessment, Integrity, Mentorship</h1>
        <div class="third-container">
        <div class="third-section">
          <h2>Оценка участников</h2>
          <p>Жюри оценивает заявки на участие в премии, уделяя внимание деталям каждого представления и его соответствию высоким стандартам премии.</p>
        </div>
        <div class="third-section">
          <h2>Объективность и честность</h2>
          <p>Члены жюри подходят к каждой заявке с максимальной объективностью и стремятся к справедливости, чтобы гарантировать, что каждый участник имеет равные шансы на успех.</p>
        </div>
        <div class="third-section">
          <h2>Менторство и развитие</h2>
          <p>Многие члены жюри также активно участвуют в образовательных инициативах, проводя мастер-классы и семинары для участников, повышая их профессиональные навыки и знания.</p>
        </div>
          </div>

          <h2 className="secondary-sub-title mt-50">Престиж жюри</h2>
        <h1 className="secondary-title">Recognition, Influence, Responsibility</h1>
        <p className='about-text'>Присутствие в жюри Global Beauty Leader не только признак профессионального признания, но и выдающаяся возможность влиять на стандарты качества и инноваций в индустрии. Это позиция, которая требует не только знаний и опыта, но и высокой степени ответственности за развитие и поддержание индустрии красоты на мировом уровне.</p>
        <p className='about-text'>Быть членом жюри Global Beauty Leader не только честь, но и возможность влиять на стандарты красоты и инновации в индустрии. Это уникальная платформа для обмена знаниями и идеями с другими лидерами мира красоты, а также шанс для личного и профессионального роста.</p>
        <p className='about-text'>Лучшие из лучших: В жюри премии попадают только те профессионалы, кто продемонстрировал исключительные умения и значительное влияние на индустрию. Это люди, чья работа вдохновляет и определяет будущее всей отрасли.</p>
        <p className='about-text'>Быть членом жюри Международной Премии Global Beauty Leader — это не только признание профессионального мастерства, но и выражение глубокого уважения со стороны коллег и всей индустрии красоты. Эта роль символизирует превосходство и лидерство, отмечая индивидуальные вклады, которые существенно трансформировали практики, стандарты и технологии на международной арене отрасли индустрии красоты.</p>

        <h2 className="secondary-sub-title mt-50">Значимость Членства в Жюри</h2>
        <h1 className="secondary-title">Prestige, Networking, Leadership</h1>
        <div class="third-container">
        <div class="third-section">
          <h2>Эталон Профессионализма</h2>
          <p>Присутствие в жюри значит быть на самом высочайшем уровне профессионализма и достижений. Члены жюри являются эталонами в своих сферах, чьи работы служат образцом для подражания. Их подходы и решения формируют направления развития в мировой индустрии красоты.</p>
        </div>
        <div class="third-section">
          <h2>Влияние на Инновации и Тренды</h2>
          <p>Профессионалы в жюри не только следят за текущими трендами, но и активно участвуют в создании новых направлений и инноваций в мире. Их оценки и мнения могут способствовать распространению новых идей и технологий на международном уровне.</p>
        </div>
        <div class="third-section">
          <h2>Менторство и Вдохновение</h2>
          <p>Члены жюри часто выступают в роли менторов для молодых специалистов и стартапов в индустрии. Они делятся своим опытом и знаниями, формируя следующее поколение лидеров отрасли.</p>
        </div>
          </div>
          <div class="third-container">
        <div class="third-section">
          <h2>Социальное Признание и Престиж</h2>
          <p>Участие в жюри укрепляет профессиональный статус и общественное признание на международной арене. Это отличает членов жюри как ведущих экспертов, которым доверяют наиболее значимые задачи и решения в индустрии</p>
        </div>
        <div class="third-section">
          <h2>Создание Сети Влиятельных Контактов</h2>
          <p>Работа в жюри дает уникальную возможность общения и сотрудничества с другими ведущими специалистами и бизнес-лидерами во всем мире. Это способствует созданию мощной профессиональной сети, которая может быть полезна для реализации совместных проектов и инициатив.</p>
        </div>
        <div class="third-section">
          <h2>Определение Будущего Индустрии</h2>
          <p>Члены жюри активно участвуют в определении курса, по которому будет двигаться индустрия мировой красоты. Их решения и экспертные оценки помогают формировать стандарты качества и этики, которые будут следовать все участники международного рынка индустрии красоты.</p>
        </div>
          </div>

          <p className="about-text mt-50">Быть членом жюри Международной Премии Global Beauty Leader — это значит оставлять заметный след в истории мировой красоты, влиять на текущие и будущие поколения профессионалов, и продолжать развивать культуру инноваций и совершенства в этой динамично развивающейся отрасли во всем мире.</p>
        <p className="about-text">Мы гордимся тем, что собрали вокруг премии Global Beauty Leader такое выдающееся сообщество международных экспертов, и вместе мы продолжаем отмечать и поощрять великолепие в индкстрии красоты.</p>
      </div>

  </div>
  );
}
export default Jury;
